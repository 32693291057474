@import './styles/var';
@import './styles/mixins';

.to_top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-image: linear-gradient(90deg, $primary-color 0%, $primary-light-shade-color 100%);
  cursor: pointer;

  span {
    transform: rotate(90deg);

    &::before {
      color: white;
    }

  }
}
.forWeb{
  @include media-breakpoint-down-sm {
   display: none;
  }
}
.forMobile{
  display: none;
  @include media-breakpoint-down-sm {
    display: block;
   }
}
.containerTextButton {
  position: absolute;
  left: 25px;
  bottom: 10px;
  transform: translateY(-50%);

  @include media-breakpoint-down-sm {
    bottom: 10px;
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%);
  }

  @include media-breakpoint-down-xsm {
    width: 100%;
    margin-left: 20px;
    // top: 200px;

    button {
      width: 100%;
    }
  }

  .heading {
    font-weight: 600;
    font-size: 60px;
    color: $white;
    text-shadow: 1px 1px 2px black;
    width: 500px;
    margin-bottom: 5px;

    @include media-breakpoint-down-sm {
      font-size: 30px;
    }
  }
}
.homeSliderImageContainer {
  position: relative;
  padding: 10px 5px 0 5px;
  cursor: pointer;

  .homeSliderHeading {
    color: #977575;
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .homeSliderImage {
    object-fit: cover;
    object-position: center;
    height: 600px;
    width: 100%;
    border-radius: 15px;


    @include media-breakpoint-down-sm {
      height: 200px;
    }
  }

  
}

.homeSliderSecondImageContainer {
  cursor: pointer;
  position: relative;
  height: 300px;
  width: 100%;
  padding: 5px;

  .heading {
    font-size: 24px;
    bottom: 5px;
    width: 185px;
    position: absolute;
  }

  img {
    border-radius: 15px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  @include media-breakpoint-down-sm {
    height: 200px;
    border-radius: 15px;
    padding: 5px;
  }
}

.home {
  h2 {
    color: $title-color ;

    @include media-breakpoint-down-sm {
      display: none;
    }
  }

  .slider_top {
    margin-top: 35px;

    @include media-breakpoint-down-sm {
      font-size: $fz-18;
      line-height: 21px;
      margin-top: 23px;
    }
  }

  .map_link {
    text-align: right;
    width: fit-content;
    float: right;

    @include media-breakpoint-down-sm {
      margin-top: -37px;
    }

    img {
      margin-right: 8px;
    }

    a {
      font-size: 12px;
      color: $text-color !important;
    }
  }
}

.home {
  .see_more {
    text-align: center;
    margin-top: 83px;
    font-size: $fz-12;
    line-height: 14px;
    text-decoration-line: underline;
    color: #747474;
    cursor: pointer;
  }
}