@import '../../styles/var';
@import '../../styles/mixins';
.slide_images{
    padding-right: 3px;
}
.image_container {
    // @include border-radius(10px);
    margin: 0;
    overflow: hidden;
    position: relative;
    display: block;
    padding-top: 100%;
    border-radius: 50%;
    border: 2px solid red;
    img {
      @include imageSize(cover, center);
      top: 0;
      left: 0;
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transition: .3s ease-in-out;
      transition: .3s ease-in-out;
      &:hover {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
      }
    }
  }
.text{
    /*color: #747474 !important;*/
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include media-breakpoint-down-sm{
      width: 100%;
      font-size: 12px;
      padding-left:7px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
}